import { FC, memo, useMemo } from 'react';
import {
  UserContactOutline,
  UnorderedListOutline,
  UserOutline,
} from 'antd-mobile-icons';
import { TabBar } from 'antd-mobile';
import { useNavigate, useLocation } from 'react-router-dom';
import { ACCOUNT, PAGE, USER } from 'until/enum';
import { useBoolean, useThrottleEffect } from 'ahooks';

const tabs = [
  {
    key: PAGE.BASE,
    title: '订单',
    icon: <UnorderedListOutline />,
  },
  {
    key: ACCOUNT.BASE,
    title: '会员',
    icon: <UserContactOutline />,
  },
  {
    key: USER.BASE,
    title: '管理员',
    icon: <UserOutline />,
  },
];

const NavTab: FC = () => {
  const navigateTo = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const [state, { setTrue, setFalse }] = useBoolean(true);

  useThrottleEffect(() => {
    if (tabs.some(({ key }) => key === pathname)) {
      setTrue();
      return;
    }
    setFalse();
  }, [pathname]);

  const activeKey = useMemo(
    () =>
      tabs.slice(1).find(({ key }) => pathname.includes(key))?.key || PAGE.BASE,
    [pathname],
  );

  return state ? (
    <div
      className="h-12 bg-white "
      style={{ borderTop: ' 1px solid var(--adm-color-border)' }}
    >
      <TabBar
        safeArea
        activeKey={activeKey}
        onChange={(value) => navigateTo(value)}
      >
        {tabs.map((item) => (
          <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
        ))}
      </TabBar>
    </div>
  ) : null;
};

export default memo(NavTab);
