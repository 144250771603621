import { Route, Routes, BrowserRouter } from 'react-router-dom';
import { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom/client';
import NavTab from 'components/NavTab';
import routes from 'routes';
import './index.css';
import { SafeArea, SpinLoading } from 'antd-mobile';

const fallback = (
  <>
    <SpinLoading
      color="primary"
      className="absolute top-0 bottom-0 left-0 right-0 m-auto"
      style={{ '--size': '60px' }}
    />
    <div className="absolute top-0 bottom-0 left-0 right-0 m-auto opacity-30 h-14 logo " />
  </>
);

const App = () => (
  <div className="fixed inset-0 flex flex-col w-screen h-screen text-base bg-gray-100 ">
    <div className="relative flex-1 h-screen overflow-auto">
      <Routes>
        {routes.map((ele) => {
          // eslint-disable-next-line
          const Com = lazy(() => import(`./${ele.loadPath}`));
          return (
            <Route
              key={ele.path}
              path={`${ele.path}`}
              element={
                <Suspense fallback={fallback}>
                  <Com />
                </Suspense>
              }
            />
          );
        })}
      </Routes>
    </div>
    <NavTab />
  </div>
);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <BrowserRouter>
    <App />
    <SafeArea position="bottom" />
  </BrowserRouter>,
);
